import Flatpickr from 'stimulus-flatpickr';

import { parseTime } from 'lib/datetime';

/**
 * flatpickr (date / time picker) controller.
 *
 * http://flatpickr.js.org
 * https://github.com/adrienpoly/stimulus-flatpickr
 */
export default class extends Flatpickr {
  // For details on flatpickr options, see https://flatpickr.js.org/options/
  static values = {
    // If true, sets various config options to show just a time picker
    time: Boolean,
    // If true, sets various config options to show a date and time picker
    dateTime: Boolean,
    // CSS class name (or space-separated names) applied to the .flatpickr-calendar element
    class: String,
    // If true, the native date picker will be used in mobile browsers
    disableMobile: Boolean,
    // On date change, window location will be set to this string with any instances
    // of ":date" (or the URL encoded version "%3Adate") replaced with the selected
    // date string, in YYYY-MM-DD format.
    linkToTemplate: String,
    // If true, the calendar is shown inline, i.e. permanently open
    inline: Boolean,
    // "single", "multiple", or "range"
    mode: String,
    // If set, the minimum date that can be selected, in YYYY-MM-DD format
    minDate: String,
    // If set, dates on any other days of the week cannot be selected
    daysOfWeek: Array,
  };

  initialize() {
    // Global config
    this.config = {
      allowInput: true,
      altInput: true,
      altFormat: 'n/j/Y',
      dateFormat: 'Y-m-d',
    };
  }

  connect() {
    // stimulus-flatpickr does not yet support the stimulus 2 values api, so any
    // values attrs we use need to be converted to the old data map api.
    // See https://flatpickr.js.org/options/ for details on supported options.

    if (this.hasModeValue) {
      this.data.set('mode', this.modeValue);
    }

    if (this.hasDisableMobileValue) {
      this.config.disableMobile = this.disableMobileValue;
    }

    if (this.hasInlineValue) {
      this.config.inline = this.inlineValue;
    }

    if (this.hasMinDateValue) {
      this.config.minDate = this.minDateValue;
    }

    if (this.hasDaysOfWeekValue) {
      const daysOfWeek = this.daysOfWeekValue.map((d) => parseInt(d, 10));
      this.config.disable = [(date) => !daysOfWeek.includes(date.getDay())];
    }

    if (this.hasLinkToTemplateValue) {
      this.config.onChange = (selectedDates, dateStr) => {
        const url = this.linkToTemplateValue
          .replaceAll(':date', dateStr)
          .replaceAll('%3Adate', dateStr);
        window.location = url;
      };
    }

    if (this.dateTimeValue) {
      this.config = {
        ...this.config,
        enableTime: true,
        dateFormat: 'Y-m-d h:i K',
        altFormat: 'n/j/Y h:i K',
      };
    } else if (this.timeValue) {
      this.config = {
        ...this.config,
        enableTime: true,
        noCalendar: true,
        altInput: false,
        dateFormat: 'h:i K',
        // prefer PM since this is used mostly for events, which mostly take place
        // after noon
        parseDate: (date) => parseTime(date, { preferPM: true }),
      };
    }

    super.connect();

    // Apply custom css classes to the .flatpickr-calendar element
    if (this.classValue) {
      const classes = this.classValue.split(' ');
      classes.forEach((class_) =>
        this.element._flatpickr.calendarContainer.classList.add(class_),
      );
    }
  }
}
