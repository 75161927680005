import { Controller } from '@hotwired/stimulus';

/**
 * excerpt controller (see #excerpt_for helper and truncate_html gem).
 * Show full excerpted content on "more" button click.
 *
 * The `type` value can be used to restrict which showAll and hideAll events
 * a controller instance responds to, in case a page has multiple separate
 * sets of excerpted data.
 */
export default class extends Controller {
  static targets = ['excerpt', 'full'];

  static values = { type: String };

  /**
   * Show "full" targets and hide "excerpt" targets.  A shift-click event will
   * trigger the showAll action.
   */
  show(event) {
    event.preventDefault();

    if (!this.isMatchingEvent(event)) {
      return;
    }

    // Note that different parts of the website use different, incompatible
    // classes for hiding and showing content, so this takes a liberal approach
    // of changing inline css and toggling classes.

    if (this.hasExcerptTarget) {
      $(this.excerptTargets).hide().addClass('hide');
    }

    if (this.hasFullTarget) {
      $(this.fullTargets).show().removeClass('hide');
    }

    if (event.type === 'click' && event.shiftKey) {
      this.showAll(event, { type: this.typeValue });
    }
  }

  /**
   * Show "excerpt" targets and hide "full" targets.  A shift-click event will
   * trigger the hideAll action.
   */
  hide(event) {
    event.preventDefault();

    if (!this.isMatchingEvent(event)) {
      return;
    }

    if (this.hasExcerptTarget) {
      $(this.excerptTargets).show().removeClass('hide');
    }

    if (this.hasFullTarget) {
      $(this.fullTargets).hide().addClass('hide');
    }

    if (event.type === 'click' && event.shiftKey) {
      this.hideAll(event, { type: this.typeValue });
    }
  }

  /**
   * Dispatch a "showAll" event to trigger other excerpt controllers, which
   * should configure an action like "excerpt:showAll@document->show".
   *
   * The "type" value is included and can be used to filter which excerpt
   * controllers respond to this showAll event.
   */
  showAll(event) {
    event.preventDefault();
    this.dispatch('showAll', { detail: { type: this.typeValue } });
  }

  /**
   * Dispatch a "hideAll" event to trigger other excerpt controllers, which
   * should configure an action like "excerpt:hideAll@document->hide".
   *
   * The "type" value is included and can be used to filter which excerpt
   * controllers respond to this hideAll event.
   */
  hideAll(event) {
    event.preventDefault();
    this.dispatch('hideAll', { detail: { type: this.typeValue } });
  }

  /**
   * Check if the event matches this controller.  An event does not match if
   * it's a showAll or hideAll event type and the event.detail.type doesn't
   * match this controller's type.
   *
   * @param event {Event}
   * @returns {Boolean}
   */
  isMatchingEvent(event) {
    if (['excerpt:showAll', 'excerpt:hideAll'].includes(event.type)) {
      return event.detail?.type === this.typeValue;
    }

    return true;
  }
}
