import { Controller } from '@hotwired/stimulus';

const VIEW_KEY = 'calendar-view';

/**
 * calendar--view controller: controls the UI for the calendar page, allowing
 * users to switch between views (week and month).
 */
export default class extends Controller {
  static targets = ['monthView', 'weekView'];

  connect() {
    this.toggleView(this.defaultView);
  }

  setView(event) {
    event.preventDefault();

    const { view } = event.params;
    this.toggleView(view);
  }

  toggleView(view) {
    this.monthViewTarget.classList.toggle('show', view === 'month');
    this.weekViewTarget.classList.toggle('show', view === 'week');

    this.defaultView = view;
  }

  /**
   * Returns the default view name, which is either:
   * - extracted from the view query param
   * - extracted from local storage
   * - defaults to "week"
   *
   * @returns {String}
   */
  get defaultView() {
    const params = new URLSearchParams(window.location.search);
    let view = params.get('view');

    if (!view) {
      try {
        view = localStorage.getItem(VIEW_KEY);
      } catch (err) {
        // ignore local storage error
      }
    }

    return view || 'week';
  }

  set defaultView(view) {
    try {
      localStorage.setItem(VIEW_KEY, view);
    } catch (err) {
      // ignore local storage error
    }
  }
}
