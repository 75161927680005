import { Application as Stimulus } from '@hotwired/stimulus';
// eslint-disable-next-line prettier/prettier
import { definitionsFromContext as definitionsFromContext_ }
  from '@hotwired/stimulus-webpack-helpers';

function dedupeIdentifier(identifier) {
  // Find a duplicate word separated by -- at the end of the identifier
  const dupe = (identifier.match(/(?:--)?(.+)--\1$/) || [])[1];

  if (dupe) {
    return identifier.replace(RegExp(`--${dupe}$`), '');
  }

  return identifier;
}

/**
 * Wrap Stimulus' definitionsFromContext helper so that identifiers have
 * their component names de-duplicated, so that a controller at a path like
 *    components/foo/foo_controller.js
 * has an identifier like
 *    foo
 * instead of
 *    foo--foo
 */
function definitionsFromContext(context) {
  return definitionsFromContext_(context).map((definition) =>
    Object.assign(definition, {
      identifier: dedupeIdentifier(definition.identifier),
    }),
  );
}

export { Stimulus, definitionsFromContext };
