import { Controller } from '@hotwired/stimulus';

/**
 * toggle controller: Toggle visibility of elements on the page.
 *
 * If the event target has a "toggle-show-value" data attribute, its value is
 * matched to the same attribute value in the toggle targets to determine whether
 * to show them.  This allows for n toggle targets, rather than just 2.
 */
export default class extends Controller {
  static targets = ['toggle'];

  static values = {
    toggledClass: String,
  };

  toggle(event) {
    const showType = event.target.dataset.toggleShowValue;

    const toggledClass = this.toggledClassValue;
    this.toggleTargets.forEach((toggle) => {
      const show = showType
        ? showType === toggle.dataset.toggleShowValue
        : undefined;

      toggle.classList.toggle('hide', showType != null ? !show : undefined);
      toggle.classList.toggle('show', show);
      if (toggledClass) {
        toggle.classList.add(toggledClass);
      }
    });

    if (event.params.preventDefault) {
      event.preventDefault();
    }
  }
}
