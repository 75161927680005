// Utilities for managing dates and times

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Day.js plugins
dayjs.extend(customParseFormat);

/* eslint-disable import/prefer-default-export */

/**
 * Parse a time value and return a Date.  String parsing applies some cleanup
 * and fairly lenient format expectations, although it only accepts values
 * that include hour and optional minute; values with seconds are rejected.
 *
 * If the value cannot be parsed into a valid time, then undefined is returned.
 *
 * For comparison, see Flatpickr's internal createDateParser:
 * https://github.com/flatpickr/flatpickr/blob/master/src/utils/dates.ts#L45
 *
 * @param date {Date | String | Number}
 * @param preferPM {Boolean} if true, times without "AM" or "PM" will be assumed
 *  to be PM
 * @returns { Date | undefined }
 */
export const parseTime = (date, { preferPM = false } = {}) => {
  // Allowed time formats.  Note that the input string is cleaned up below
  // by changing to uppercase and removing anything that's not a digit, colon,
  // or AM/PM
  const timeFormats = [
    // 12 hour time, without minutes, with AM/PM
    'hA',
    'hhA',

    // 24 hour time, without minutes, with or without AM/PM
    'H',
    'HH',
    'HA',
    'HHA',

    // 12 hour time, with minutes, with AM/PM
    'h:mmA',
    'hh:mmA',

    // 24 hour time, with minutes, with or without AM/PM
    'H:mm',
    'HH:mm',
    'H:mmA',
    'HH:mmA',
  ];

  if (date !== 0 && !date) {
    return undefined;
  }

  if (date instanceof Date) {
    return new Date(date.getTime());
  }

  // timestamp
  if (typeof date !== 'string' && date.toFixed !== undefined) {
    return new Date(date);
  }

  if (typeof date === 'string') {
    let cleanedDate = date
      .toUpperCase()
      .replace(/[^\d:AMP]/g, '') // remove extraneous chars
      .trim();

    // Append "M" if the string ends in "A" or "P" for lazy typers
    if (cleanedDate.endsWith('A') || cleanedDate.endsWith('P')) {
      cleanedDate = `${cleanedDate}M`;
    }

    // Handle missing AM and PM when we prefer PM (e.g. for events, which mostly
    // take place after noon)
    if (
      preferPM &&
      !cleanedDate.includes('M') &&
      !cleanedDate.startsWith('0') // leading 0 indicates 24 hour time
    ) {
      cleanedDate = `${cleanedDate}PM`;
    }

    return dayjs(cleanedDate, timeFormats, true).toDate();
  }

  return undefined;
};
