import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

/**
 * calendar--embed-form controller: update the embed code for the calender embed form.
 *
 * The embed form displays HTML code for embedding the calendar.  The form inputs
 * customize display params for the calendar.
 */
export default class extends Controller {
  static targets = [
    'baseUrl',
    'height',
    'defaultView',
    'code',
    'responsiveWidth',
    'width',
    'widthContainer',
  ];

  connect() {
    this.updateCode();
  }

  /**
   * Update the value of the code target with HTML code representing
   */
  updateCode() {
    const responsiveWidth = this.responsiveWidthTarget.checked;

    $(this.widthContainerTarget).toggle(!responsiveWidth);

    const height = this.heightTarget.value || 600;
    const baseUrl = this.baseUrlTarget.value;
    const embedDefaultView = this.defaultViewTarget.value;

    // Note: width is only used for the iframe, as our embedded calendar
    // is full-width.
    let iframeWidth = (this.widthTarget.value || 800).toString();
    const iframeHeight = `${parseInt(height, 10) + 250}px`;

    if (!iframeWidth.endsWith('%')) {
      iframeWidth = `${parseInt(iframeWidth, 10)}px`;
    }

    if (responsiveWidth) {
      iframeWidth = '100%';
    }

    const url = encodeURI(
      `${baseUrl}?embed=1&view=${embedDefaultView}&;h=${height}`,
    );

    const embedCode =
      `<iframe frameborder="0" border="0" seamless="seamless" src="${url}" ` +
      `style="border: 0px; height: ${iframeHeight}; width:${iframeWidth}"></iframe>`;

    this.codeTarget.value = embedCode;
  }
}
