import { Controller } from '@hotwired/stimulus';
import { atcb_action } from 'add-to-calendar-button'; // eslint-disable-line camelcase

/**
 * calendar--add-to-calendar controller: add to calendar button.
 *
 * Provide an easy means for members to add an event to their personal
 * calendar app (Apple Calendar, Google Calendar, etc.).
 */
export default class extends Controller {
  static values = {
    description: String,
    location: String,
    title: String,
    startDate: String,
    startTime: String,
    endDate: String,
    endTime: String,
    timeZone: String,
    icsFile: String,
  };

  add() {
    const params = {
      name: this.titleValue,
      description: this.descriptionValue,
      startDate: this.startDateValue,
      endDate: this.endDateValue,
      location: this.locationValue,
      options: [
        'Apple',
        'Google',
        'iCal',
        'Microsoft365',
        'MicrosoftTeams',
        'Outlook.com',
        'Yahoo',
      ],
      timeZone: this.timeZoneValue,
      trigger: 'click',
      bypassWebViewCheck: true,
      icsFile: this.icsFileValue,
    };

    // A start time cannot be provided without an end time, and vice versa.
    // If an event has no start time, treat it as an all-day event.
    // If an event has a start time but no end time, use the start time as the end time.
    if (this.startTimeValue) {
      params.startTime = this.startTimeValue;
      params.endTime = this.endTimeValue || this.startTimeValue;
    }

    atcb_action(params);
  }
}
